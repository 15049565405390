.dot.selected{
    width:  0.729vw!important;
    height: 0.729vw!important;
    background-color: #00A99D!important;
    border-radius: 10px!important;
    box-shadow: none!important;
    border-radius: 35%!important;
}

.dot{
    opacity: 1!important;
    box-shadow: none!important;
    width:  0.729vw!important;
    height: 0.729vw!important;
    border: 2px solid #00A99D;
    border-radius: 35%!important;
}

.slider-wrapper{
    width: 68.75vw!important;
    height: 35.4vw!important;
}

.control-arrow{
  display: none;
}

.control-prev{
    left: -1.2%!important;
}

.control-next{
    right: -1.2%!important;
}

.carousel-root{
    z-index: 1!important;
}

.car-div{
    position: relative;
    width: 99%;
    height: 100%;
    display: flex;
    justify-content: space-between;
}

.carousel-img{
    width: 45vw!important;
    height: 35.4vw!important;
}

.carousel-content{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 23.43vw;
    text-align: start;
}


.underlinenone{
    text-decoration: none;
}

.underlinenone:hover{
    text-decoration: underline;
    text-decoration-color: #37424B ;
}

.slide{
    min-height: 10vw;
}

.carousel .slider-wrapper{
    min-height: 20vw;
    margin-top: 2vw!important;
}


.carousel-header{
    color: #37424B;
    font-size: 1.04vw;
    font-family: "BPG Nino Mtavruli", sans-serif;
    width: 20vw;
    margin-top: 7.8vw;
}

.carousel-date{
    color: #37424B;
    font-size: 1.04vw;
    font-family: "BPG Nino Mkhedruli", sans-serif;
    width: 19vw;
    margin-top: 0.2vw;
}

.carousel-text{
    color: #37424B;
    font-size: 1.04vw;
    font-family: "BPG Nino Mkhedruli", sans-serif;
    width: 21vw;
    margin-top: 1vw;
}

.carousel .control-dots{
    bottom: 25%!important;
    right: 35%!important;
}



@media only screen and (max-width: 650px) {

    .dot.selected{
        width: 7.5vw!important;
    }

    .carousel .slide img{
        height: 50vh!important;
        object-fit: cover;
    }

    .control-arrow{
      display: none;
    }

    .carousel-content {
        width: 75vw;
        height: 40vw;
    }

    .carousel-content label {
        font-weight: 900;
        font-size: 6vw;
    }

    .carousel-content p {
        font-size: 3.9vw;
       
    }

    .carousel-content button {
        font-size: 4.15vw;
        border-radius: 5.8vw;
        width: 35vw;
        height: 10.64vw;
    }

}