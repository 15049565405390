.home-hr{
    width: 68.75vw;
    height: 1px;
    background-color: #37424B;
}

.home-grid{
    display: grid;
    width: 68.75vw;
    grid-template-columns: auto auto auto;
    justify-content: space-between;
    margin-top: 3.125vw;
}

.homecard-div{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 21.875vw;
    margin-bottom: 2vw;
}

.homecard-div img{
    width: 21.875vw;
    height: 13.5vw;
    object-fit: cover;
}

.homecard-div h2{
    font-size: 1.04vw;
    color: #37424B;
    font-family: "BPG Nino Mtavruli", sans-serif;
    margin-top: 2vw;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.homecard-div span{
    font-size: 1.04vw;
    color: #37424B;
    font-family: "BPG Nino Mkhedruli", sans-serif;
}

.homecard-div p{
    font-size: 1.04vw;
    color: #37424B;
    font-family: "BPG Nino Mkhedruli", sans-serif;
    margin-top: 1vw;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.Committeecard-div{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 15.62vw;
    margin-bottom: 2vw;
}

.Committeecard-div img{
    width: 15.625vw;
    height: 9.375vw;
    object-fit: cover;
}

.Committeecard-div h2{
    font-size: 1.04vw;
    color: #37424B;
    font-family: "BPG Nino Mtavruli", sans-serif;
    margin-top: 2vw;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.Committeecard-div span{
    font-size: 1.04vw;
    color: #37424B;
    font-family: "BPG Nino Mkhedruli", sans-serif;
}

.Committeecard-div p{
    font-size: 1.04vw;
    color: #37424B;
    font-family: "BPG Nino Mkhedruli", sans-serif;
    margin-top: 1vw;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.other-news-home{
    font-size: 0.83vw;
    color: #37424B;
    font-family: "BPG Nino Mtavruli", sans-serif;
    font-weight: 900;
    display: flex;
    align-items: center;
    margin-bottom: 0.5vw;
    margin-top: 0.5vw;
}

.other-news-home img{
    width: 0.7vw;
    margin-left: 0.4vw;
    margin-bottom: 0.3vw;
}

.margintop1p5{
    margin-top: 1.5vw!important;
}

.green-bar{
    margin-top: 3vw;
    margin-bottom: 3vw;
    background-color: rgba(29, 144, 136, 0.2);
    width: 100%;
    height: 14.5vw;
}

@media only screen and (max-width: 650px) {

    .home-hr{
        width: 84vw;
        height: 1px;
        background-color: #37424B;
    }
    
    .home-grid{
        display: flex;
        width:84vw;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin-top: 7.125vw;
    }
    
    .homecard-div{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 84vw;
        margin-bottom: 8vw;
    }
    
    .homecard-div img{
        width: 84vw;
        height: 65vw;
        object-fit: cover;
    }
    
    .homecard-div h2{
        font-size: 4.04vw;
        color: #37424B;
        font-family: "BPG Nino Mtavruli", sans-serif;
        margin-top: 2vw;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    
    .homecard-div span{
        font-size: 4.04vw;
        margin-top: -2vw;
        color: #37424B;
        font-family: "BPG Nino Mkhedruli", sans-serif;
    }
    
    .homecard-div p{
        font-size: 1.04vw;
        color: #37424B;
        font-family: "BPG Nino Mkhedruli", sans-serif;
        margin-top: 1vw;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        display: none;
    }
    
    .Committeecard-div{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 86vw;
        margin-bottom: 2vw;
    }
    
    .Committeecard-div img{
        width: 86vw;
        height:55vw;
        object-fit: cover;
    }
    
    .Committeecard-div h2{
        font-size: 4.5vw;
        color: #37424B;
        font-family: "BPG Nino Mtavruli", sans-serif;
        margin-top: 2vw;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    
    .Committeecard-div span{
        font-size: 4.04vw;
        color: #37424B;
        font-family: "BPG Nino Mkhedruli", sans-serif;
    }
    
    .Committeecard-div p{
        font-size: 4.04vw;
        color: #37424B;
        font-family: "BPG Nino Mkhedruli", sans-serif;
        margin-top: -1vw;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    
    .other-news-home{
        font-size: 3.5vw;
        color: #37424B;
        font-family: "BPG Nino Mtavruli", sans-serif;
        font-weight: 900;
        display: flex;
        align-items: center;
        margin-bottom: 0.5vw;
        margin-top: 2.5vw;
    }
    
    .other-news-home img{
        width: 3vw;
        margin-left: 2.4vw;
        margin-bottom: 0.3vw;
    }
    
    .margintop1p5{
        margin-top: 1.5vw!important;
    }
    
    .green-bar{
        margin-top: 5vw;
        margin-bottom: 5vw;
        background-color: rgba(29, 144, 136, 0.2);
        width: 100%;
        height: 55vw;
    }
}