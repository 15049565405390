.article-flex-between{
    width: 68.75vw;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 2vw;
}

.article-left{
    width: 45.3vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.article-left img{
    width: 100%;
}

.article-left h2{
    font-size: 1.04vw;
    color: #37424B;
    font-family: "BPG Nino Mtavruli", sans-serif;
    font-weight: 900;
    margin-top: 2vw;
}

.article-left span{
    font-size: 1.04vw;
    color: #37424B;
    font-family: "BPG Nino Mkhedruli", sans-serif;
    font-weight: 900;
    margin-top: 1vw;
}

.article-left p{
    font-size: 1.04vw;
    color: #37424B;
    font-family: "BPG Nino Mkhedruli", sans-serif;
    font-weight: 500;
}

.article-right{
    width: 21.85vw;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.other-news{
    font-size: 1.14vw;
    color: #37424B;
    font-family: "BPG Nino Mtavruli", sans-serif;
    font-weight: 900;
    display: flex;
    align-items: center;
    margin-bottom: 1vw;
}

.other-news img{
    width: 1.14vw;
    margin-left: 0.4vw;
    margin-bottom: 0.3vw;
}

.other-article{
    width: 21.85vw;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 2vw;
}

.other-article img{
    width: 6.25vw;
    height: 3.9vw;
    object-fit: cover;
}

.other-article h2{
    font-size: 1.04vw;
    color: #37424B;
    font-family: "BPG Nino Mtavruli", sans-serif;
    font-weight: 900;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
}

.other-article span{
    font-size: 1.04vw;
    color: #37424B;
    font-family: "BPG Nino Mkhedruli", sans-serif;
    font-weight: 900;
}

.other-article-content{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin-left: 1.04vw;
    height: 3.9vw;
    width: 14vw;
}

.other-articles{
    display: flex;
    flex-direction: column;
}

.team-top{
    margin-top: 2vw;
    width: 68.75vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 23.95vw;
    margin-bottom: 3vw;
}

.team-top img{
    width: 45.3vw;
    height: 23.95vw;
    object-fit: cover;
}

.team-top-left{
    width: 21.87vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 23.95vw;
}

.team-top-left p{
    font-size: 1.25vw;
    font-family: "BPG Nino Mkhedruli", sans-serif;
    font-weight: 900;
    line-height: 1.2;
}

.team-top label{
    width: 100% ;
    height: 3.6vw;
    background-color: rgba(29, 144, 136, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.35vw;
    font-family: "BPG Nino Mtavruli", sans-serif;
    font-weight: 900;
}

.team-top-text{
    width: 68.75vw;
    columns: 3;
    column-gap: 2vw;
    font-size: 1.04vw;
    font-family: "BPG Nino Mkhedruli", sans-serif;
    margin-bottom: 3vw;
    line-height: 1.2;
}

.team-bottom{
    margin-top: 5vw;
    width: 68.75vw;
    display: grid;
    grid-template-columns: auto auto auto;
    justify-content: space-between;
    margin-bottom: 3vw;
}

.member-card{
    width: 21.87vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.member-card img{
    width: 100%;
    height: 11.5vw;
}

.member-card h3{
    font-size: 1.04vw;
    color: #37424B;
    font-family: "BPG Nino Mtavruli", sans-serif;
    font-weight: 900;
    margin-top: 2vw;
    margin-bottom: 0vw;
}

.member-card span{
    font-size: 1.04vw;
    color: #37424B;
    font-family: "BPG Nino Mkhedruli", sans-serif;
    font-weight: 500;
    margin-top: 0.3vw;
}

.member-card p{
    margin-top: 1.5vw;
    font-size: 1.04vw;
    color: #37424B;
    font-family: "BPG Nino Mkhedruli", sans-serif;
    font-weight: 500;
    line-height: 1.2;
}

.Team-list{
    display: grid;
    grid-template-columns: 16vw 16vw 16vw;
    width: 68.75vw;
    justify-content: space-between;
}

.Team-list-member{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 2vw;
}

.Team-list-member h4{
    margin-top: 0vw;
    font-size: 1.04vw;
    color: #37424B;
    font-family: "BPG Nino Mkhedruli", sans-serif;
    font-weight: 500;
}

.Team-list-member h3{
    margin-top: 0vw;
    font-size: 1.04vw;
    color: #37424B;
    font-family: "BPG Nino Mtavruli", sans-serif;
    font-weight: 900;
}

.Committee-list{
    margin-top: 2vw;
    display: grid;
    grid-template-columns: auto auto auto auto;
    width: 68.75vw;
    justify-content: space-between;
}

.defaulta{
    color: #37424B!important;
}

@media only screen and (max-width: 650px) {

    .article-flex-between{
        width: 86vw;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin-top: 2vw;
    }
    
    .article-left{
        width: 86vw;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
    
    .article-left img{
        width: 100%;
    }
    
    .article-left h2{
        font-size: 4.04vw;
        color: #37424B;
        font-family: "BPG Nino Mtavruli", sans-serif;
        font-weight: 900;
        margin-top: 2vw;
    }
    
    .article-left span{
        font-size: 3.5vw;
        color: #37424B;
        font-family: "BPG Nino Mkhedruli", sans-serif;
        font-weight: 900;
        margin-top: 1vw;
    }
    
    .article-left p{
        font-size: 3.5vw;
        color: #37424B;
        font-family: "BPG Nino Mkhedruli", sans-serif;
        font-weight: 500;
    }
    
    .article-right{
        width: 86vw;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }
    
    .other-news{
        font-size: 3.54vw;
        color: #37424B;
        font-family: "BPG Nino Mtavruli", sans-serif;
        font-weight: 900;
        display: flex;
        align-items: center;
        margin-bottom: 1vw;
    }
    
    .other-news img{
        width: 3.54vw;
        margin-left: 2.4vw;
        margin-bottom: 1.3vw;
    }
    
    .other-article{
        width: 40vw;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 2vw;
    }
    
    .other-article img{
        width: 40vw;
        height: 23.5vw;
        object-fit: cover;
        margin-bottom: 2vw;
    }
    
    .other-article h2{
        font-size: 3.5vw;
        color: #37424B;
        font-family: "BPG Nino Mtavruli", sans-serif;
        font-weight: 900;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 0;
    }
    
    .other-article span{
        font-size: 3.04vw;
        color: #37424B;
        font-family: "BPG Nino Mkhedruli", sans-serif;
        font-weight: 900;
        margin-top: 2vw;
    }
    
    .other-article-content{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        height: max-content;
        width: 40vw;
    }
    
    .other-articles{
        display: flex;
        flex-direction: row;
        width: 86vw;
        justify-content: space-between;
        align-items: flex-start;
    }
    
    
    .team-top{
        margin-top: 2vw;
        width: 86vw;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 3vw;
        height: unset;
    }
    
    .team-top img{
        width: 86vw;
        height: 55vw;
        object-fit: cover;
    }
    
    
    .team-top-leftp{
        font-size: 4vw;
        font-family: "BPG Nino Mkhedruli", sans-serif;
        font-weight: 900;
        line-height: 1.2;
    }
    
    .team-top label{
        width: 100% ;
        height: 15vw;
        background-color: rgba(29, 144, 136, 0.2);
        display: flex;
        align-items: center;
        justify-content: center;
        font-size:5.5vw;
        font-family: "BPG Nino Mtavruli", sans-serif;
        font-weight: 900;
        margin-top: 2vw;
        margin-bottom: 4vw;
    }
    
    .team-top-text{
        width: 86vw;
        columns: 1;
        font-size: 4.04vw;
        font-family: "BPG Nino Mkhedruli", sans-serif;
        margin-bottom: 3vw;
        line-height: 1.2;
        margin-top: -4vw;
    }
    
    .team-bottom{
        margin-top: 5vw;
        width: 86vw;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 3vw;
    }
    
    .member-card{
        width: 86vw;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }
    
    .member-card img{
        width: 100%;
        height: 55vw;
        object-fit: cover;
    }
    
    .member-card h3{
        font-size: 5vw;
        color: #37424B;
        font-family: "BPG Nino Mtavruli", sans-serif;
        font-weight: 900;
        margin-top: 2vw;
        margin-bottom: 0vw;
    }
    
    .member-card span{
        font-size: 4.5vw;
        color: #37424B;
        font-family: "BPG Nino Mkhedruli", sans-serif;
        font-weight: 500;
        margin-top: 0.3vw;
    }
    
    .member-card p{
        margin-top: 1.5vw;
        font-size: 4.04vw;
        color: #37424B;
        font-family: "BPG Nino Mkhedruli", sans-serif;
        font-weight: 500;
        line-height: 1.2;
    }
    
    .Team-list{
        display: flex;
       flex-direction: column;
        width: 84vw;
        justify-content: flex-start;
        align-items: flex-start;
    }
    
    .Team-list-member{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 2vw;
    }
    
    .Team-list-member h4{
        margin-top: 1vw;
        font-size: 4.04vw;
        color: #37424B;
        font-family: "BPG Nino Mkhedruli", sans-serif;
        font-weight: 500;
    }
    
    .Team-list-member h3{
        margin-top: 1vw;
        font-size: 4.04vw;
        color: #37424B;
        font-family: "BPG Nino Mtavruli", sans-serif;
        font-weight: 900;
    }
    
    .Committee-list{
        margin-top: 2vw;
        display: flex;
        flex-direction: column;
        width:86vw;
        justify-content: flex-start;
        align-items: center;
    }
}
