@import url("//cdn.web-fonts.ge/fonts/bpg-nino-mkhedruli/css/bpg-nino-mkhedruli.min.css");
@import url("//cdn.web-fonts.ge/fonts/bpg-nino-mtavruli/css/bpg-nino-mtavruli.min.css");
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Display:wght@200;300;400;500;600;700;800;900&display=swap');

html{
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: 'Noto Sans Display';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* zoom: 0.9;  */
  background-color: #f4f4f4;
}



button{
  cursor: pointer;
}

/* font-family: "BPG Nino Mkhedruli", sans-serif; */
/* font-family: "BPG Nino Mtavruli", sans-serif; */
