.join-fixed{
    display: flex;
    flex-direction: column;
    width: 54vw;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: #f4f4f4;
    position: fixed;
    top: 4vw;
    left: 14.5vw;
    height: 5vw;
}

.join-fixed span{
    color: #00A99D;
    font-size: 1.35vw;
    font-family: "BPG Nino Mtavruli", sans-serif;
    font-weight: 900;
    margin-top: 2vw;
    margin-bottom: 0.6vw;
}

.join-hr{
    width: 50vw;
    height: 1px;
    background-color: #37424B;
}

.join-menu-fixed{
    position: fixed;
    top: 8.3vw;
    right: 15.5vw;
    height: 20vw;
    display: flex;
    width: 17vw;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border-left: 1px solid #37424B;
    background-color: #f4f4f4;
}

.join-menu-fixed a{
    font-size: 1vw;
    font-family: "BPG Nino Mtavruli", sans-serif;
    font-weight: 900;
    color: #37424B;
    width: 14vw;
    margin-left: 1.6vw;
    margin-bottom: 1vw;
    line-height: 1.2;
    cursor: pointer;
}

.join-menu-fixed a:hover{
    color: #00A99D;
}

.join-topic{
    width: 50vw;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    margin-right: 20vw;
}

.join-topic h2{
    font-size: 1vw;
    font-family: "BPG Nino Mtavruli", sans-serif;
    font-weight: 900;
    color: #37424B;
}

.join-topic p{
    font-size: 1vw;
    font-family: "BPG Nino Mkherduli", sans-serif;
    font-weight: 500;
    color: #37424B;
}

.margintopbig{
    margin-top: 9vw;
}

:target:before {
    content: "";
    display: block;
    margin: 10vw 0 0;
}


@media only screen and (max-width: 650px) {
    .join-fixed{
        display: flex;
        flex-direction: column;
        width: 84vw;
        justify-content: flex-start;
        align-items: flex-start;
        background-color: #f4f4f4;
        position: fixed;
        top: 20vw;
        left: 8vw;
        height: 10vw;
    }
    
    .join-fixed span{
        color: #00A99D;
        font-size: 4vw;
        font-family: "BPG Nino Mtavruli", sans-serif;
        font-weight: 900;
        margin-top: 4vw;
        margin-bottom: 0.6vw;
    }
    
    .join-hr{
        width: 80vw;
        height: 1px;
        background-color: #37424B;
    }
    
    .join-menu-fixed{
        position: fixed;
        top: 24vw;
        right: 5.5vw;
        height: 20vw;
        display: flex;
        width: 40vw;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        border-left: 1px solid #37424B;
        background-color: #f4f4f4;
        overflow-y: auto;
    }
    
    .join-menu-fixed a{
        font-size: 4vw;
        width: 30vw;
        margin-left: 1.6vw;
        margin-bottom: 1vw;
        line-height: 1.2;
        cursor: pointer;
    }
    
    .join-topic{
        margin-top: 20vw;
        width: 80vw;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        text-align: left;
        margin-right: 4vw;
    }
    
    .join-topic h2{
        font-size: 5vw;
        font-family: "BPG Nino Mtavruli", sans-serif;
        font-weight: 900;
        color: #37424B;
    }
    
    .join-topic p{
        font-size: 3vw;
    }
    
    .margintopbig{
        margin-top: 30vw;
    }
    
    :target:before {
        content: "";
        display: block;
        margin: 30vw 0 0;
    }
}